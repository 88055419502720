.Select {
  width: 100%;
  min-width: 120px;
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-gray-50);
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-align-last: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Select:hover {
  opacity: 0.7;
}

.Select:focus {
  outline: none;
}
.Select option {
  text-align: center;
  text-align: -webkit-center;
}
