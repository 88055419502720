.DropDownList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}

.Line {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-10);
  border-radius: 99px;
}

.DropDownContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
}
.DropDownContent > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DropDownContent > div:hover {
  cursor: pointer;
  opacity: 0.8;
}
.DropDownContent > div {
  user-select: none;
}

.DropDownContent > div > h5,
.DropDownContent > p {
  margin: 0;
}

.DropDownContent > div > h5 {
  font-size: 16px;
  font-weight: 500;
}

.DropDownContent > p {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray-80);
}
