.ChartWrapperContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  height: fit-content;
}
.DescriptionArc {
  margin: 0px;
  font-size: 16px;
  background-color: var(--color-stroke);
  color: var(--color-gray-50);
  padding: 5px 10px;
  border-radius: 10px;
}

.ChartArcWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.ChartArcRelativeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
}

.ChartArcRelativeWrapper p {
  margin: 0;
}

.ChartArcLegend {
  font-weight: 500;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .ChartArcWrapper {
    flex-direction: column;
    gap: 20px;
  }
}
