.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.Hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 50px 50px;
}
.Hero > h1 {
  margin: 0;
  text-align: center;
}

.BlogGrid {
  display: grid;
  grid-template-columns: repeat(3, 500px);
  align-items: start;
  justify-items: center;
  gap: 60px;
  padding: 30px 30px;
}

.BlogGrid > div {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.BlogText > p,
.BlogText > h3,
.BlogText > div > h3 {
  margin: 0px;
}

.BlogText > div {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.BlogText > div > h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.BlogImage {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.RelativeBlogImage {
  position: relative;
}

.ImageBottomGlass {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  height: 80px;
  background: rgba(110, 107, 107, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.6px);
  -webkit-backdrop-filter: blur(5.6px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ImageBottomGlassLeft > p {
  margin: 0px;
}
.ImageBottomGlassLeft {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.TitleImageBottomGlass {
  font-weight: 600;
  font-size: 14px;
}
.ImageBottomGlassRight {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}
.ImageBottomGlassRight > p {
  margin: 0px;
  background-color: var(--color-primary);
  padding: 6px 10px;
  border-radius: 99px;
  font-size: 12px;
}

.BlogText {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 16px;
  gap: 5px;
  width: 100%;
}

.BlogText > h3 {
  font-size: 18px;
  font-weight: 600;
}

.BlogText > h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.BlogText > p {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray-50);
}

.BlogText > button {
  display: flex;
  align-items: start;
  justify-content: center;
  border: none;
  text-decoration: none;
  font-weight: 600;
  background: none;
  color: var(--color-black);
  cursor: pointer;
  padding: 0px;
  font-size: 16px;
  margin-top: 10px;
}

.BlogText > button:hover {
  text-decoration: underline;
}

.BlogPagination {
  width: 100%;
  max-width: 1100px;
  padding: 20px;
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.IconWrapper:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (max-width: 1700px) {
  .BlogGrid {
    grid-template-columns: repeat(2, 500px);
  }
}

@media screen and (max-width: 1100px) {
  .BlogGrid {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}
