.Navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--color-stroke);
}

.NavigationContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 20px;
  /* max-width: 1100px; */
}

.LogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0px;
}

.LogoWrapper > h2 {
  margin: 0px;
  font-size: 24px;
  cursor: pointer;
}

.LogoWrapper > a {
  margin: 0;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;
  color: var(--color-gray-50);
  text-wrap: nowrap;
  text-decoration: none;
}
.LogoWrapper > a:hover {
  opacity: 0.7;
}

.NavigationContent h2 {
  font-weight: 600;
  margin: 0;
  text-wrap: nowrap;
}

.LeftSideNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;
  width: 100%;
}

.RightSideNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
}

.NavigationList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.NavigationList > li {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavigationList > li:hover {
  opacity: 0.8;
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  position: relative;
}

.FiltersWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.Hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Hamburger:hover {
  opacity: 0.6;
}

@media screen and (max-width: 700px) {
  .NavigationContent {
    margin-top: 20px;
  }
  .NavigationContent h2 {
    text-align: left;
    width: 100%;
    margin: 0;
  }

  .Buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 40px;
  }
}
