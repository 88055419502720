.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  padding: 100px 30px;
  gap: 40px;
}

.CategoryDateWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.CategoryWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(226, 85, 226, 0.1);
  border-radius: 99px;
}

.CategoryDateWrapper > p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray-50);
}

.ContentTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.ContentTitleWrapper > h2 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}

.ContentTitleWrapper > p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}

.BlogContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  overflow: auto;
  height: fit-content;
}

.BlogContent a {
  color: var(--color-primary);
}

.BlogContent img {
  max-width: 800px;
}

.Line {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-10);
}

.rdwEditorMain {
  overflow: hidden !important;
  min-height: 0 !important;
}
