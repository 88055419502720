.Table {
  width: 100%;
  border-spacing: 0px;
  position: relative;
  border-collapse: collapse;
}

.TableContainer {
  width: 100%;
  overflow-y: auto;
  position: relative;
  /* max-height: calc(100vh - 200px); */
}

.Headers {
  position: sticky;
  top: 0;
  z-index: 0;
  text-align: right;
  font-weight: 500;
  background-color: white;
  border-bottom: 1px solid var(--color-stroke);
  font-size: 14px;
  cursor: pointer;
  text-wrap: nowrap;
  width: fit-content;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Headers:first-child {
  padding-left: 15px;
}
.Headers:last-child {
  padding-right: 15px;
}

.Cells {
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  width: fit-content;
  /* padding: 20px 5px; */
  cursor: pointer;
  padding-right: 30px;
  /* border-style: none solid solid none;
  border: solid 1px #000; */
}

.Cells:first-child {
  padding-left: 15px;
}

.Cells:last-child {
  padding-right: 15px;
}

.Cells > p {
  margin: 0px;
}

.rowsCell {
  border-bottom: 1px solid var(--color-gray-stroke);
}

.rowsCell:hover {
  background-color: var(--color-gray-5);
  overflow: hidden;
}

.rowsCell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Noresults {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 5px;
}

.Noresults > h2,
.Noresults > p {
  margin: 0px;
}

.Noresults > h2 {
  font-size: 38px;
  font-weight: 500;
}

.Noresults > p {
  font-size: 18px;
}
