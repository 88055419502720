.ModalWrapperBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000030;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.ModalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 30px;
}

.MiddleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ModalList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
}

.ModalList > li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 15px 0px;
  font-weight: 500;
  cursor: pointer;
}
.ModalList > li:hover {
  opacity: 0.6;
}
.CloseBtn {
  width: 100%;
  text-align: end;
}
.CloseBtn > img {
  cursor: pointer;
}
.CloseBtn > img:hover {
  opacity: 0.6;
}

@media screen and (max-width: 900px) {
  .ModalWrapper {
    width: 50%;
  }
}
