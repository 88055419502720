.Page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.PageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  gap: 40px;
}

.PageTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.PageTitle > h3 {
  font-size: 40px;
  margin: 0;
}
.PageTitle > p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray-70);
  text-align: center;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  gap: 20px;
}
