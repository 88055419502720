.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .ModalWrapper {
  background-color: var(--color-white);
  width: fit-content;
  height: fit-content;
  padding: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid rgba(255, 255, 255, 0.16);
} */

/* .Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
} */

.ModalWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: fit-content;
  height: 100%;
  max-height: fit-content;
  max-width: 100vh;
  background-color: var(--color-white);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  gap: 10px;
  overflow: auto;
}
.ModalWrapperNormal {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: fit-content;
  /* height: fit-content; */
  height: 100%;
  max-height: fit-content;
  max-width: 100vh;
  background-color: var(--color-white);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  gap: 10px;
  overflow: auto;
}
.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.Title {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  width: 100%;
  text-align: left;
}

.ModalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .ModalWrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
    align-items: start;
    justify-content: start;
    border-radius: 0;
  }
}
