.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  gap: 30px;
}

.CmsWrapper {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
}

.CmsEditor {
  min-height: 275px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: auto;
}
.CmsEditor img {
  max-width: 400px;
}

.TopWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.MessageWrapper {
  text-decoration: underline;
  color: var(--color-primary);
  font-weight: 500;
}

.FormGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-items: center;
  width: 100%;
  gap: 20px;
}

.FormLeftGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .FormGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .TopWrapper {
    flex-direction: column;
  }
}
